<template>
  <div class="page-container">
    <div class="page-content">
      <div class="container">
        <span class="page-title">
          <FallBack style="background: #fff;">
            <template v-slot:title>
                    <span style="color: #222;background: #fff;">{{ $t('navbar.share') }}</span>
                </template>
          </FallBack>
        </span>
        <div class="content">
          <div class="invite-btn" @click="torecruit">
            {{ $t('share.invite') }}
          </div>
          <div class="box">
            <div :class="activeStyle == 'zh' ? 'box-item-zh' : 'box-item'">
              <div class="dot"></div> <span  >{{ $t('share.describe1') }}</span>
            </div>
            <div :class="activeStyle == 'zh' ? 'box-item-zh' : 'box-item'" style="margin-top: 11px;">
              <div class="dot"></div> <span style="flex: 1;">{{ $t('share.describe2') }}</span>
            </div>
          </div>
          <div class="box">
            <div :class="activeStyle == 'zh' ? 'box-item-zh' : 'box-item'">
              <div class="dot"></div> <span style="flex: 1;">{{ $t('share.describe3') }}</span>
            </div>
            <div :class="activeStyle == 'zh' ? 'box-item-zh' : 'box-item'" style="margin-top: 11px;">
              <div class="dot"></div> <span >
                {{ $t('share.describe4') }}
                </br> {{ $t('share.describeItem1') }}
                </br> {{ $t('share.describeItem2') }}
                </br> {{ $t('share.describeItem3') }}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <NavBar :active="3" />
  </div>
</template>

<script>
import NavBar from '@/components/NavBar';
import { sessionCache } from '@/utils/storage';
import FallBack from "@/components/FallBack/FallBack.vue";

export default {
  components: {
    FallBack,
    NavBar
  },
  data(){
    return{
      activeStyle:''
    }
  },
  methods:{
    torecruit(){
    // recruit
    this.$router.push('/Recruit')
    }
  },
  mounted(){
    this.activeStyle = sessionCache.getItem('locale')
  }
};
</script>

<style lang="scss" scoped>
@import './style.scss';
</style>
