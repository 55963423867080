export default [
  {
    name: 'Register',
    path: '/register',
    component: () => import(/* webpackChunkName: "register" */ '@/views/register/register.vue')
  },
  {
    name: 'Home',
    path: '/home',
    meta: {
      keepAlive: true,
     },
    component: () => import(/* webpackChunkName: "home" */ '@/views/home/home.vue')
  },
  {
    name: 'Shop',
    path: '/shop',
    component: () => import(/* webpackChunkName: "shop" */ '@/views/shop/shop.vue')
  },
  {
    name: 'PowerBankMap',
    path: '/powerBankMap',
    component: () => import(/* webpackChunkName: "powerBankMap" */ '@/views/powerBankMap/powerBankMap.vue')
  },
  {
    name: 'Record',
    path: '/record',
    component: () => import(/* webpackChunkName: "record" */ '@/views/record/record.vue')
  },
  {
    name: 'My',
    path: '/my',
    component: () => import(/* webpackChunkName: "my" */ '@/views/my/my.vue')
  },
  {
    name: 'Recruit',
    path: '/recruit',
    component: () => import(/* webpackChunkName: "recruit" */ '@/views/record/recruit/recruit.vue')
  },
  {
    name: 'projectIntroduction',
    path: '/ProjectIntroduction',
    component: () => import(/* webpackChunkName: "projectIntroduction" */ '@/views/home/projectIntroduction/index.vue')
  },
  {
    name: 'pubordinateRecharge',
    path: '/PubordinateRecharge',
    component: () => import(/* webpackChunkName: "pubordinateRecharge" */ '@/views/my/pubordinateRecharge/index.vue')
  },
  {
    name: 'myTeam',
    path: '/MyTeam',
    component: () => import(/* webpackChunkName: "MyTeam" */ '@/views/my/myTeam/index.vue')
  },
  {
    name: 'passwordSecurity',
    path: '/PasswordSecurity',
    component: () => import(/* webpackChunkName: "passwordSecurity" */ '@/views/my/passwordSecurity/index.vue')
  },
  {
    name: 'editPassword',
    path: '/EditPassword',
    component: () => import(/* webpackChunkName: "editPassword" */ '@/views/my/editPassword/index.vue')
  },
  {
    name: 'withdraw',
    path: '/Withdraw',
    component: () => import(/* webpackChunkName: "withdraw" */ '@/views/my/withdraw/index.vue')
  },
  {
    name: 'recharge',
    path: '/Recharge',
    component: () => import(/* webpackChunkName: "withdraw" */ '@/views/my/recharge/index.vue')
  },
  {
    name: 'myEarnibgs',
    path: '/MyEarnibgs',
    component: () => import(/* webpackChunkName: "myEarnibgs" */ '@/views/my/myEarnibgs/index.vue')
  },
  {
    name: 'wallet',
    path: '/Wallet',
    component: () => import(/* webpackChunkName: "wallet" */ '@/views/my/wallet/index.vue')
  },
  {
    name: 'finance',
    path: '/Finance',
    component: () => import(/* webpackChunkName: "finance" */ '@/views/home/finance/index.vue')
  },
  {
    name: 'financeRecords',
    path: '/FinanceRecords',
    component: () => import(/* webpackChunkName: "financeRecords" */ '@/views/home/financeRecords/index.vue')
  },
  {
    name: 'announcement',
    path: '/Announcement',
    component: () => import(/* webpackChunkName: "announcement" */ '@/views/my/announcement/index.vue')
  },
  {
    name: 'announcementDetails',
    path: '/AnnouncementDetails',
    component: () => import(/* webpackChunkName: "announcementDetails" */ '@/views/my/announcementDetails/index.vue')

  },
  {
    name: 'aboutUs',
    path: '/AboutUs',
    component: () => import(/* webpackChunkName: "aboutUs" */ '@/views/my/aboutUs/index.vue')

  },
  {
    name: 'withdrawalAccount',
    path: '/WithdrawalAccount',
    component: () => import(/* webpackChunkName: "withdrawalAccount" */ '@/views/my/withdrawalAccount/index.vue')

  },
  {
    name: 'history',
    path: '/History',
    component: () => import(/* webpackChunkName: "history" */ '@/views/my/history/index.vue')

  },
  {
    name: 'turntable',
    path: '/Turntable',
    component: () => import(/* webpackChunkName: "turntable" */ '@/views/home/turntable/index.vue')

  },
  {
    name: 'authentication',
    path: '/Authentication',
    component: () => import(/* webpackChunkName: "authentication" */ '@/views/my/authentication/index.vue')

  },
  {
    name: 'financeDetail',
    path: '/FinanceDetail',
    component: () => import(/* webpackChunkName: "financeDetail" */ '@/views/home/financeDetail/index.vue')

  },
  {
    name: 'contract',
    path: '/Contract',
    meta: {
      keepAlive: true,
     },
    component: () => import(/* webpackChunkName: "contract" */ '@/views/home/contract/index.vue')
  },
];
