import Record from "@/views/record/record.vue";

export default {
  login: {
    login: 'Login',
    subhead: 'Login To The Universall Movie',
    account: 'Phone number & Country',
    password: 'Password',
    forgot: 'Forgot Password?',
    registerText: 'Don’t have an account?',
    register: 'Register',
    sign: 'By Continuing you are agree to the Terms of Service and Privacy Policy',
    singHelp: 'Check this box if you want to continue',
    userLogin:'User Login',
    userRegister:'User Registration',
    email:'E-mail',
    remPwd:'Remember password',
    confirm:'confirm',
    loginNow:'Register now',
    haveAccount:'Already have an account',
    avaCode:'verification code',
    avaLoginPwd:'Verify login password',
    referralCode:'Referral code',
  },
  navbar: {
    home: 'Home',
    energy: 'Energy',
    share: 'Share',
    my: 'My',
    position:'Position'
  },
  Finance:{
    finance:'Finance',
    progress:'Progress',
    ended:'Ended',
    purchasehistory:'Purchase History',
    pending:'Pending',
    completed:'Completed',
    name:'Project',
    referenceAnnualization:'Reference annualization',
    LockInPeriod:'Lock-in period',
    cumulativeIncome:'Cumulative income',
    startingime:'Starting time',
    nextRebateTime:'Next rebate time',
    unlock:'Unlock',
    remainingPositions: 'Remaining Positions',
    lockedPositions: 'Locked Positions',
    minutes:'Minutes',
    joinNow:'Join Now',
    tradingRules:'Trading rules',
    returnType:'Rebate method',
    dailyRebate:'Daily rebate',
    detailTitle:'Project Details'
  },
  recharge:{
    Recharge:'Recharge',
    Balance:'Balance',
    Rechargechannel:'Recharge Channel',
    placeholder:'Please enter the amount',
    Exchangerate:'Exchange rate',
    Paymentamount:'Payment amount',
    Confirm:'Confirm',
    Handlingfee:'Handling fee',
    Amounttoaccount:'Amount to account',
    Withdrawnow:'Withdraw now',
    Withdraw:'Withdraw',
    Record:'Record',
    TypePaypassword: 'Please enter the payment password'
  },
  Earnings:{
    title:'My earnings',
    myBalance:'My balance',
    TotalRevenue:'Total revenue',
    Withdrawn:'Withdrawn',
    TurntableConsumption:'Turntable consumption',
    Withdraw:'Withdraw',
    Buypowerbank:'Buy power bank',
    Powerbankinterest:'Power bank interest',
    Buyfinance:'Buy finance',
    Powerbankreturnstheprincipal:'Power bank returns the principal',
    Financialmanagementreturnstheprincipal:'Financial management returns the principal',
    Systemperation:'System operation',
  },
  wallet:{
    title:'My wallet',
    balance:'Balance',
  },
  announcement:{
    title:'Announcement',
    viewdetails:'View Detail',
  },
  team:{
    title:'My team',
    pushstraight:'Push straight',
    nondDirectPush:'Non-direct push',
     earn:'His earnings',
    subordinate:'His subordinate'
  },
  withdrawalAccount:{
    title:'Withdrawal account',
    placeholder:'Please enter TRON address',
    bind:'Bind',
    wait:'Pending review',
    success:'Success',
    fail: 'Fail'
  },
  subordinaterecharge:{
    subordinaterecharge:'Subordinate recharge',
    FirstLevel:'First-level recharge',
    SecondLevel :'Second-level recharge',
    ThreeLevel :'Three-level recharge',
    FourLevel:'Four-level recharge'
  },
  password:{
    Passwordsecurity:'Password security',
    Modifyloginpassword:'Modify login password',
    Modifypaymentpassword:'Modify payment password',
    Oldpassword:'Old password',
    Newpassword:'New password',
    Confirmthenewpassword:'Confirm the newpassword',
    Confirm:'Confirm',
  },
  tabList:{
    recharge:'Recharge',
    carouselDraw:'Carousel Draw',
    finance:'Finance',
    projectIntroduction:'Project Introduction'
  },
  productList:{
        title:'Shared charging · Shared revenue',
        button:'Lease now',
        rentalFee:'Rental fee',
        dailyIncome:'Daily income',
        earningsDays:'Earnings days',
        totalRevenue:'Total revenue',
  },
  interest:{
    profitOfTheDay:'Profit of the day',
    amountThatCanBeWithdrawn:'Amount that can be withdrawn',
    totalRevenue:'Total revenue',
    teamSize:'Team size',
  },
  actionsList:{
    passwordSecurity:'Password security',
    onlineService:'Online service',
    subordinateRecharge:'Subordinate recharge',
    myeam:'My team',
    withdraw:'Withdraw',
    recharge:'Recharge',
    withdrawalAAccount:'Withdrawal account',
    myEarnings:'My earnings',
    myWallet:'My wallet',
    announcement:'Announcement',
    aboutUs:'About us',
    APPDownload:'APP download',
    signOut:'Sign Out',
    partnerNumber:'Partner number',
    authentication: 'Authentication',
     earn:'won',
      title:'Records of winning users',
      start:'Start the draw',
    thank:'Thanks for participating',
    account: "Account",

  },
  share:{
    invite:'Invite now' ,
    describe1:'Click the invite now button to invite friends to share income together' ,
    describe2:'The more friends you invite, the more daily income' ,
    describe3:'The team subordinates the mobile power supply, the agent can get the team level 3 rebate' ,
    describe4:'For example, when you invite friends to rent, they are 1 level of subordinate. The lease of the 1 level of the affairs invitation is Level 2 subordinate, and the 2-level subsidiary friend lease is Level 3 subordinate. Friends, friends who are invited at Level 3, agents can get the percentage of all their commissions ' ,
    describeItem1:' Level 1 rebate 8% ' ,
    describeItem2:' Level 2 rebate 5% ' ,
    describeItem3:' Level 3 rebate 2% ' ,
  },
  map:{
    title:'Product distribution',
    statusY:'yes',
    statusN:'no',
    returnY:'Returnable',
    returnN:'UnReturnable'
  },
  energy:{
    title:'Energy',
    share:'Sharing',
    end:'end',
    rentalfee:' Rental fee',
    dailyincome:'Daily income',
    totalrevenue:'Total revenue',
    expectedearnings:'  Expected earnings',
    settlement:'24 hours settlement',
    receive: 'Receive benefits',
    shareHour: 'Shared for {count} hour',
    toward: 'Running'
  },
  userInfo: {
    tips:'tips',
    cancel:'cancel',
    confirm:'confirm',
     youLink:'Your invite link',
    copy:'Copy'
  },
  home: {
    typeBuyNum: 'Please enter the purchase quantity',
    dontLottery: 'Didn\'t win the lottery',
    contract: 'Contractual agreement',
    contractTip1: '[This agreement is guaranteed by the following banking institutions]',
    contractTip2: 'Guaranteed by: (Bank of China, Agricultural Bank of China, China Construction Bank, China Corporate Bank, Citibank USA, ABN AMRO, Standard Chartered Bank, Bank of America)',
    agreeContract: 'Please agree to the contract agreement'
  },
  authentication:{
    title:'Authentication',
    name:'name',
    IDcardNum:'IDcard Number',
    IDcardImg:'IDcard Image',
    submit:'Submit',
    nameTips:'Please fill in your name',
    IDcardTips:'Please fill in your IDcard Num',
    notCompleted:'Data not completed',
    approved: 'Approved',
    submitted: 'Submitted',
  },
  My: {
    system: "System operation",
    child_reg: "Subordinate registration gift",
    sign: "Sign in gift",
    recharge: "Recharge",
    withdraw: "Withdraw",
    withdrawFail: "Withdrawal failed",
    buyMD: "Buy power bank",
    commissionMD: "Power bank interest",
    returnMD: "Power bank returns the principal",
    fenxiao: "Subordinates buy power bank rebates",
    MDprofit: "Subordinate income rebate",
    buyLicai: "Buy finance",
    returnLicai: "Financial management returns the principal",
    commissionLicai: "Financial rebate",
    zhuanpan: "Turntable consumption",
  },
  common: {
    getVerifyCode: "GetVerifyCode",
  }
};
