import Vue from 'vue';
import VueI18n from 'vue-i18n';
import zhLocale from './zh';
import enLocale from './en';
import {  sessionCache,localCache } from '@/utils/storage';

Vue.use(VueI18n);

const messages = {
  zh: { ...zhLocale },
  en: { ...enLocale }
};

const i18n = new VueI18n({
  locale: localCache.getItem('locale') ,
  fallbackLocale: 'zh',
  silentFallbackWarn: true, // 禁用缺失翻译的警告
  silentTranslationWarn: true,
  messages:messages
});

export default i18n;
