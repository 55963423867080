import Vue from 'vue';
import VueRouter from 'vue-router';
import Login from '@/layout/login/login.vue';
import Pages from './pages';
import NotFound from '@/layout/NotFound.vue';
import { sessionCache } from '@/utils/storage';


const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
     return originalPush.call(this, location).catch(err => err)
}
Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    redirect: '/home'
  },
  {
    name: 'Login',
    path: '/login',
    meta: { title: '登录' },
    component: Login
  },
  ...Pages,
  {
    name: 'NotFound',
    path: '/*',
    meta: { title: '404' },
    component: NotFound
  }
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
});

router.beforeEach((to, from, next) => {
  const token = sessionCache.getItem('token');
  console.log(token, 'token')
  console.log(to.path, 'path')
  if (to.path !== '/login' &&  !token)   {
    if( to.path == '/home'){
      next()
    }else{
      next()
    }
    next()
  
  } else {
    next()
  }

});
console.log(Pages, 'pages')

export default router;
