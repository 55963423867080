// /user/register
import { HTTP } from '@/utils/request';
// 登录 
export function fetchHome(data) {
    return HTTP.post({
      url: '/system/home',
      data,
      showLoading: true
    });
  }
  export function fetchGetList(data) {
    return HTTP.post({
      url: '/financeDM/list',
      data,
      showLoading: true
    });
    
  }
  export function fetchGetSetting(data){
    return HTTP.post({
      url: '/system/setting',
      data,
      showLoading: true
    });
  }
  // 获取金融列表
  export function fetchFinanceList(data){
    return HTTP.post({
      url: '/finance/list',
      data,
      showLoading: true
    });
    
  }
  // 获取购买历史列表
  export function fetchFinanceRecords(data){
    return HTTP.post({
      url: '/finance/history',
      data,
      showLoading: true
    });
    
  }
  // 获取地图pos
  export function getPostList(data){
    return HTTP.post({
      url: '/system/map',
      data,
      showLoading: true
    });
 
  }
  // 获取抽奖结果
  export function getzhuanpan(data){
    return HTTP.post({
      url: '/user/zhuanpan',
      data,
      showLoading: true
    });
   
  }
  export function purchase(data){
    return HTTP.post({
      url: '/financeDM/purchase',
      data,
      showLoading: true
    });
   
  }
  export function getFinaceDetail(data){
    return HTTP.post({
      url: '/finance/detail',
      data,
      showLoading: true
    });
   
  }
  export function financeCreate(data){
    return HTTP.post({
      url: '/finance/create',
      data,
      showLoading: true
    });
  }

  