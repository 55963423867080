// /user/register
import { HTTP } from '@/utils/request';
// 登录
export function fetchLogin(data) {
    return HTTP.post({
      url: '/user/login',
      data,
      showLoading: true
    });
  }
// 注册
export function register(data){
    return HTTP.post({
        url: '/user/register',
        data,
        showLoading: true
      });
}
// 忘记密码
export function reset(data){
  return HTTP.post({
    url: '/user/reset',
    data,
    showLoading: true
  });

}
// 获取code
export function getCode(data){
  return HTTP.post({
    url: '/sms/send',
    data,
    showLoading: true
  });
}

// 获取验证码
export function verifyCode(data){
  return HTTP.post({
    url: '/sms/verify',
    data,
    showLoading: true
  })
}

// 设置收款地址
export function setAddress(data){
  return HTTP.post({
    url: '/user/set_address',
    data,
    showLoading: true
  })
}

// 个人中心
export function userInfo(data){
  return HTTP.post({
    url: '/user/user_info',
    data,
    showLoading: true
  });
}
export function cal(data){
  return HTTP.post({
    url: '/financeDM/cal',
    data,
    showLoading: true
  });
}
export function friendsRecharge(data){
  return HTTP.post({
    url: '/user/friends_recharge',
    data,
    showLoading: true
  });
}
export function friends(data){
  return HTTP.post({
    url: '/user/friends',
    data,
    showLoading: true
  });
}
// 更改支付或者登录密码
export function changePwd(data){
  return HTTP.post({
    url: '/user/reset_auth',
    data,
    showLoading: true
  });
}
// 获取提现渠道
export function getChanelList(data){
  return HTTP.post({
    url: '/userCreditOrder/channel',
    data,
    showLoading: true
  });
}
// 提现
export function withdraw(data){
  return HTTP.post({
    url: '/userCreditOrder/withdraw',
    data,
    showLoading: true
  });
}
// 充值
export function recharge(data){
  return HTTP.post({
    url: '/userCreditOrder/create',
    data,
    showLoading: true
  });
}
// 我的收入 记录
export function creditLogs(data){
  return HTTP.post({
    url: '/credit/logs',
    data,
    showLoading: true
  });
}
// 我的收入 收入明细
export function creditRecord(data){
  return HTTP.post({
    url: '/financeDM/credit',
    data,
    showLoading: true
  });
}
// 公告列表
export function announcementList(data){
  return HTTP.post({
    url: '/article/list',
    data,
    showLoading: true
  });
}
// 公告详情
export function announcementDetails(data){
  return HTTP.post({
    url: '/article/detail',
    data,
    showLoading: true
  });
}
// 登出
export function signOut(data){
  return HTTP.post({
    url: '/user/logout',
    data,
    showLoading: true
  });
}

// 历史记录
export function withdrawList(data){
  return HTTP.post({
    url: '/userCreditOrder/withdraw_list',
    data,
    showLoading: true
  });
}
// 签到
export function fetchSign(data){
  return HTTP.post({
    url: '/user/sign',
    data,
    showLoading: true
  });
}

export function fetchAccount(data){
  return HTTP.post({
    url: '/account/get',
    data,
    showLoading: true
  });
}
// 上传

export function upload(data){
  return HTTP.upload({
    url: '/system/upload',
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    data,
    showLoading: true
  })
}
export function accountBind(data){
  return HTTP.post({
    url: '/account/bind',
    data,
    showLoading: true
  });
}
export function getAccount(data){
  return HTTP.post({
    url: '/account/get',
    data,
    showLoading: true
  });
}



