<template>
  <div class="nav-bar">
    <template v-for="(item, index) in menus" >
      <div  class="nav-item" :key="index" @click="handleChangeMenu(item.path)">
        <div class="nav-icon">
          <img
            :class="index == 2 ? 'icon1':'icon' "
            :src="
              require(`@/assets/img/navbar/${item.icon + (active === index ? '-active' : '')}.png`)
            "
            alt="icon"
          />

        </div>
        <div class="nav-label" :class="{ active: index === active }">{{ item.label }} </div>
      </div>
    </template>
  </div>
</template>

<script>
import NavBar from '.';

export default {
  name: 'NavBar',
  props: {
    active: Number
  },
  data() {
    return {
      menus: [
        {
          label: this.$t('navbar.home'),
          icon: 'home',
          path: '/home'
        },
        {
          label: this.$t('navbar.energy'),
          icon: 'energy',
          path: '/shop'
        },
        {
          label: this.$t('navbar.position'),
          icon: 'logo',
          path: '/powerBankMap'
        },
        // {
        //   label: this.$t('navbar.share'),
        //   icon: 'share',
        //   path: '/record'
        // },
        {
          label: this.$t('navbar.my'),
          icon: 'my',
          path: '/my'
        }
      ]
    };
  },
  methods: {
    handleChangeMenu(path) {
      if (path === '/powerBankMap') {
        window.location.href = 'https://www.tesla.cn/findus?filters=supercharger%2Cdestination%20charger';
        return;
      }

      this.$router.push(path);
    }
  }
};
</script>

<style lang="scss" scoped>
@import './style.scss';
</style>
