import userInfo from "@/store/user";

export default {
  login: {
    login: '登录',
    subhead: '登录到 Universall Movie',
    account: '手机号码 & 地区',
    password: '密码',
    forgot: '忘记密码？',
    registerText: '没有账号？',
    register: '注册账号',
    sign: '继续使用即表示您同意本服务条款和隐私政策',
    singHelp: '如果想要继续，请选中此框',
    userLogin:'用户登录',
    userRegister:'用户注册',
    email:'电子邮件',
    remPwd:'记住密码',
    confirm:'确定',
    loginNow:'现在注册',
    haveAccount:'已有账号',
    avaCode:'验证码',
    avaLoginPwd:'验证登录密码',
    referralCode:'推介代码',
  },
  navbar: {
    home: '首页',
    energy: '能量',
    share: '分享',
    my: '我的',
    position:'位置'
  },
  Finance:{
    finance:'金融',
    progress:'进行中',
    ended:'结束',
    purchasehistory:'购买记录',
    pending:'待办的',
    completed:'已完成',
    name:'项目',
    referenceAnnualization:'参考年化',
    LockInPeriod:'锁定期',
    cumulativeIncome:'累计收入',
    startingime:'起始时间',
    nextRebateTime:'下次返利时间',
    unlock:'开锁',
    remainingPositions: '剩余仓位',
    lockedPositions: '锁定仓位',
    minutes:'分钟',
    joinNow:'现在加入',
    tradingRules:'交易规则',
    returnType:'返利方式',
    dailyRebate:'每日返现',
    detailTitle:'项目细节'

  },
  recharge:{
    Recharge:'提取',
    Balance:'余额',
    Rechargechannel:'提现渠道',
    placeholder:'请输入金额',
    Exchangerate:'汇率',
    Paymentamount:'支付金额',
    Confirm:'确认',
    Handlingfee:'手续费',
    Amounttoaccount:'到账金额',
    Withdrawnow:'立即提款',
    Withdraw:'提取',
    Record:'记录',
    TypePaypassword: '请输入支付密码',
  },
  password:{
    Passwordsecurity:'密码安全',
    Modifyloginpassword:'修改登录密码',
    Modifypaymentpassword:'修改支付密码',
    Oldpassword:'旧密码',
    Newpassword:'新密码',
    Confirmthenewpassword:'确认新密码',
    Confirm:'确认',
  },
  Earnings:{
    title:'我的收入',
    myBalance:'我的余额',
    TotalRevenue:'总收入',
    Withdrawn:'撤销',
    TurntableConsumption:'转盘消费',
    Withdraw:'提取',
    Buypowerbank:'购买充电桩',
    Powerbankinterest:'充电桩利息',
    Buyfinance:'购买金融',
    Powerbankreturnstheprincipal:'充电桩还本金',
    Financialmanagementreturnstheprincipal:'理财归还本金',
    Systemperation:'系统操作',
  },
  withdrawalAccount:{
    title:'提款账户',
    placeholder:'请输入TRC20地址',
    bind:'绑定',
    wait:'等待审核',
    success:'成功',
    fail: '失败'
  },
  announcement:{
    title:'公告',
    viewdetails:'查看详情',
  },
  wallet:{
    title:'我的钱包',
    balance:'余额',
  },
  subordinaterecharge:{
    subordinaterecharge:'从属充值',
    FirstLevel:'一级充值',
    SecondLevel :'二级充值',
    ThreeLevel :'三级充值',
    FourLevel:'四级充值'
  },
  team:{
    title:'我的团队',
    pushstraight:'直推',
    nondDirectPush:'非直推',
    earn:'他的收入',
    subordinate:'他的下属'
  },
  tabList: {
    recharge: '充值',
    carouselDraw: '轮播抽奖',
    finance: '金融',
    projectIntroduction: '项目介绍',

  },
  productList: {
    title: '共享充电，共享收益',
    button: '立即租用',
    rentalFee: '租金',
    dailyIncome: '每日收入',
    earningsDays: '收益天数',
    totalRevenue: '总收入',
  },
  userInfo: {
    tips:'提示',
    cancel:'取消',
    confirm:'确认',
    youLink:'您的邀请链接',
    copy:'复制'
  },
  interest: {
    profitOfTheDay: '当日利润',
    amountThatCanBeWithdrawn: '可提取金额',
    totalRevenue: '总收入',
    teamSize: '团队规模',
  },
  actionsList: {
    passwordSecurity: '密码安全',
    onlineService: '在线服务',
    subordinateRecharge: '从属充值',
    myeam: '我的团队',
    withdraw: '提取',
    recharge: '充值',
    withdrawalAAccount: '提款账户',
    myEarnings: '我的收入',
    myWallet: '我的钱包',
    announcement: '公告',
    aboutUs: '关于我们',
    APPDownload: 'APP下载',
    signOut: '登出',
    partnerNumber: '合作伙伴编号',
    authentication: '实名认证',
    earn:'赢',
    title:'获奖用户记录',
    start:'开始抽奖',
    thank:'感谢参与',
    account: "账号"
  },
  share: {
    invite: '立即邀请',
    describe1: '点击立即邀请按钮，邀请好友一起分享收益',
    describe2: '邀请的朋友越多，每日收入越多',
    describe3: '团队下属充电桩，代理可获得团队3级返利',
    describe4: '例如，当您邀请朋友租房时，他们是 1 级下属。事务邀请的1级租约为2级从属，2级子好友租约为3级从属。朋友，3级邀请的朋友，代理商可以获得他们所有佣金的百分比',
    describeItem1: '一级返利 8%',
    describeItem2: ' 2级返利 5% ',
    describeItem3: ' 3级返利 2% ',
  },
  energy:{
    title:'能量',
    share:'分享',
    end:'结束',
    rentalfee:' 租金',
    dailyincome:'每日收入',
    totalrevenue:'总收入',
    expectedearnings:'  预期收益',
    settlement:'24小时结算',
    receive: '领取收益',
    shareHour: '共享对象 {count} 小时',
    toward: '进行中'
  },
  map:{
    title:'产品分销',
    statusY:'是的',
    statusN:'是的',
    returnY:'可退货',
    returnN:'可退货'
  },
  home: {
    typeBuyNum: '请输入购买数量',
    dontLottery: '你没有中奖',
    contract: '用户协议',
    contractTip1: '【本协议由以下银行机构担保】',
    contractTip2: '担保机构:（中国银行，中国农业银行，中国建设银行，中国公司银行，美国花旗银行，荷兰银行，渣打银行，美国银行）',
    agreeContract: '请同意用户协议'
  },
  authentication:{
    title:'身份验证',
    name:'姓名',
    IDcardNum:'身份证号码',
    IDcardImg:'身份证正反面',
    submit:'提交',
    nameTips:'请输入姓名',
    IDcardTips:'请输入身份证号码',
    notCompleted:'数据为填写完成',
    approved: '已实名',
    submitted: '已提交',
  },
  My: {
    system: "系统操作",
    child_reg: "下属注册礼物",
    sign: "签到礼物",
    recharge: "充值",
    withdraw: "提取",
    withdrawFail: "提款失败",
    buyMD: "购买充电桩",
    commissionMD: "充电桩利息",
    returnMD: "充电桩还本金",
    fenxiao: "下属购买充电桩返利",
    MDprofit: "次级收入返还",
    buyLicai: "购买金融",
    returnLicai: "理财归还本金",
    commissionLicai: "财务回扣",
    zhuanpan: "转盘消费",
  },
  common: {
    getVerifyCode: "获取验证码",
  }
};
