import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import LoadingPlugin from '@/components/Loading';
import i18n from './language';
import VueClipboard from 'vue-clipboard2'
import BaiduMap from 'vue-baidu-map'
import '@/utils/flexible';
import '@/assets/css/base.css';
import '@/assets/css/theme.css';
import '../mock';

// Vue.use(BaiduMap, { ak: 'KkGKfAT5i3kvS4XAhSgABWtHwPAkrS3T' })
Vue.use(LoadingPlugin);
Vue.use(VueClipboard)
Vue.config.productionTip = false;

new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App)
}).$mount('#app');
