import Request from './Request';
import {localCache, sessionCache} from '@/utils/storage';
console.log(process.env.VUE_APP_BASE_URL,'process')
let requestUrl = process.env.VUE_APP_BASE_URL
export const HTTP = new Request({
  baseURL: requestUrl,
  timeout: 1000 * 60,
  interceptors: {
    requestInterceptor: (config) => {
      // 接口国际化
      config.headers.Lang = localCache.getItem('locale');
      // 携带token
      const token = sessionCache.getItem('token');
      if (token && config.headers) {
        config.headers.Authorization = `Bearer ${token}`;
      }
      return config;
    }
  }
});
