<template>
  <div>
  <div class="flex-center lang" @click="pickerShow = !pickerShow">
     {{ locale == 'zh' ? '简体中文' : 'English' }}
    <div :class=" pickerShow ? 'picker-active':'picker' " style="transform: rotate(180deg);"></div>
  </div>
  <div class="login-container">

    <div class="form-box">
      <div class="title-box">
        <div class="title" v-if="pageTab == 'login'">{{ $t('login.userLogin') }}</div>
        <div class="title" v-if="pageTab == 'register'">{{ $t('login.userRegister') }}</div>
        <div class="title" v-if="pageTab == 'password'">{{ $t('login.forgot') }}</div>
        <div class="logo-bg" @click="toServiceUrl"></div>
      </div>
      <template v-if="pageTab == 'login'">
        <div class="input-item">
          <div class="form-input">
            <input class="uni-input-input" v-model="loginPerson.phone" :placeholder="$t('login.email')" />
          </div>
        </div>
        <div class="input-item">
          <div class="form-input">
            <input class="uni-input-input" type="password" v-model="loginPerson.password"
              :placeholder="$t('login.password')" />
          </div>
        </div>
        <div class="input-item">
          <div class="form-checked">
            <div class="left-box">
              <div class="checkbox">
                <Checkbox v-model="checkbox" />
              </div>
              <label class="title">{{ $t('login.remPwd') }}</label>
            </div>
            <div class="right-box">
              <label @click="pageTab = 'password'" class="forget-password">{{ $t('login.forgot') }}</label>
            </div>
          </div>
        </div>
        <div class="submit-operation">
          <div class="submit-btn" @click="login">{{ $t('login.login') }}</div>
          <div class="register-link" @click="pageTab = 'register'">{{ $t('login.register') }}</div>
        </div>
      </template>
      <template v-if="pageTab == 'register'">
        <div class="input-item">
          <div class="form-input">
            <input class="uni-input-input" v-model="registerPerson.phone" :placeholder="$t('login.email')" />
          </div>
        </div>
        <div class="input-item">
          <div class="form-input">
            <input class="uni-input-input" type="password" v-model="registerPerson.password"
              :placeholder="$t('login.password')" />
          </div>
        </div>
        <div class="input-item">
          <div class="form-input">
            <input class="uni-input-input" type="password" v-model="registerPerson.confirmPassword"
              :placeholder="$t('login.avaLoginPwd')" />
          </div>
        </div>
        <div class="input-item">
          <div class="form-input">
            <input class="uni-input-input" v-model="registerPerson.invite_code"
              :placeholder="$t('login.referralCode')" />
          </div>
        </div>
        <div class="input-item">
          <div class="form-input" style="padding-right: 0;display: flex;">
            <input style="flex: 1;" class="uni-input-input" v-model="registerPerson.code"
              :placeholder="$t('login.avaCode')" />
            <div class="code-btn" @click="getAvaCode(registerPerson.phone)">{{ $t('common.getVerifyCode') }}</div>
          </div>
        </div>
        <div class="submit-operation">
          <div class="submit-btn" @click="register"> {{ $t('login.loginNow') }}</div>
          <div class="register-link" @click="pageTab = 'login'"> {{ $t('login.haveAccount') }}</div>
        </div>
      </template>
      <template v-if="pageTab == 'password'">
        <div class="input-item">
          <div class="form-input">
            <input class="uni-input-input" v-model="passwordPerson.phone" :placeholder="$t('login.email')" />
          </div>
        </div>
        <div class="input-item">
          <div class="form-input" style="padding-right: 0;display: flex;">
            <input style="flex: 1;" class="uni-input-input" v-model="passwordPerson.code"
              :placeholder="$t('login.avaCode')" />
            <div class="code-btn" @click="getAvaCode(passwordPerson.phone)">{{ $t('common.getVerifyCode') }}</div>
          </div>
        </div>
        <div class="input-item">
          <div class="form-input">
            <input class="uni-input-input" type="password" v-model="passwordPerson.password"
              :placeholder="$t('password.Newpassword')" />
          </div>

        </div>
        <div class="input-item">
          <div class="form-input">
            <input class="uni-input-input" type="password" v-model="passwordPerson.confirmPassword"
              :placeholder="$t('password.Confirmthenewpassword')" />
          </div>
        </div>
        <div class="submit-operation">
          <div class="submit-btn" @click="resetPwd">{{ $t('login.confirm') }}</div>
          <!-- <div class="register-link" @click="pageTab='login'">登录</div> -->
          <div class="register-link" @click="pageTab = 'login'">{{ $t('login.login') }}</div>
        </div>
      </template>
    </div>
  </div>
  <Popup v-model="pickerShow" v-if="pickerShow" round position="bottom">
        <Picker
        @confirm="confirmI18n"
        @cancel="cancelI18n"
        :confirm-button-text="$t('userInfo.confirm')"
        :cancel-button-text="$t('userInfo.cancel')"
        @change="changeI18n"
        show-toolbar
         :default-index="PikerdefaultIndex"
        :columns="langList" item-height="100px">
        </Picker>
      </Popup>
</div>
</template>

<script>
import { Form, Field, Checkbox, Toast,Popup ,Picker,Dialog} from 'vant';
import { fetchLogin, register, getCode, reset } from '@/service/user';
import { localCache, sessionCache } from '@/utils/storage';
import { fetchGetSetting } from "@/service/home";
export default {
  name: 'Login',
  components: {
    Form,
    Field,
    Checkbox,
    Toast,
    Popup,
    Dialog,
    Picker,
    [Dialog.Component.name]: Dialog.Component
  },
  data() {
    return {
      pageTab: 'login',
      checkbox: true,
      pickerShow: false, //语言切换
      langList: [{ text: 'English', value: 'en' }, { text: '简体中文', value: 'zh' }],
     PikerdefaultIndex:'',
      locale: '', //
      loginPerson: {
        password: '',
        phone: '',
        token: ''
      },
      registerPerson: {
        password: '',
        phone: '',
        invite_code: '',
        confirmPassword: '',
        code: ''
      },
      passwordPerson: {
        type: 'password',
        token: '',
        code: '',
        confirmPassword: '',
        password: '',
        phone: ''
      }
    }
  },
  created() {
    // 获取当前页面的完整 URL
    const currentUrl = window.location.href;
    // 使用 URL 对象解析 URL
    const url = new URL(currentUrl);
    // 获取 URL 参数
    const params = new URLSearchParams(url.search);

    let code = params.get('code');
    if (code) {
      this.pageTab = 'register';
      this.registerPerson.invite_code = code;
    }
       this.locale = localCache.getItem('locale')
    this.langList.forEach((item,i) => {
      if( item.value == this.locale){
        this.PikerdefaultIndex = i
      }
    });
  },

  methods: {
        // 国际化切换
        changeI18n(selectedValues, selectedOptions) {
      this.pickerValue = selectedOptions.value
    },
    //
    confirmI18n() {
      this.locale = this.pickerValue
      localCache.setItem('locale', this.locale)
      this.pickerShow = !this.pickerShow
      window.location.reload();
    },
    cancelI18n() {
      this.pickerValue = ''
      this.langList.forEach((item,i) => {
      if( item.value == this.locale){
        this.PikerdefaultIndex = i
      }
    });
      this.pickerShow = !this.pickerShow
    },
    // 登录
    async login() {
      let params = {
        password: this.loginPerson.password,
        phone: this.loginPerson.phone,
        token: this.loginPerson.token,
      }
      let res = await fetchLogin(params)
      if (res.status_code == 400) {
        Toast(`${res.message}`)

      } else if (res.status_code == 200) {
        Toast(`${res.message}`)
        sessionCache.setItem('token', res.data.token);
        this.$router.push('/home');
      }
    },
    // 注册
    async register() {
      let that = this
      try {
        let params = {
          code: this.registerPerson.code,
          confirmPassword: this.registerPerson.confirmPassword,
          invite_code: this.registerPerson.invite_code,
          password: this.registerPerson.password,
          phone: this.registerPerson.phone,
          token: '',
        }
        let res = await register(params)
        if (res.status_code == 200) {
          this.pageTab = 'login'
        } else {
          Toast(`${res.message}`)
        }
      } catch (error) {

      }
    },
    async resetPwd() {
      let that = this
      try {
        let params = {
          code: that.passwordPerson.code,
          confirmPassword: that.passwordPerson.confirmPassword,
          password: that.passwordPerson.password,
          phone: that.passwordPerson.phone,
          token: '',
          type: that.passwordPerson.type,
        }
        let res = await reset(params)
        if (res.status_code == 200) {
          that.pageTab = 'login'
        } else {
          Toast(`${res.message}`)
        }
      } catch (error) {
        console.log(error);

      }
    },

    // 获取二维码
    async getAvaCode(account) {
      try {
        let params = {
          account: account,
          token: ""
        }
        let res = await getCode(params)
        Toast(`${res.message}`)
      } catch (error) {

      }
    },
    // 跳转到客服
    async toServiceUrl() {
      try {
        let params = {
          slug: "kf_url",
          token: '',
        }
        let res = await fetchGetSetting(params)
        if (res.status_code == 200) {
          window.open(res.data, '_self')
        } else {

        }
      } catch (error) {

      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import './style.scss';
</style>
<style scoped>
.checkbox>>>.van-checkbox__icon {
  font-size: 3rem;
  margin-right: 8px;
}

.checkbox>>>.van-checkbox__icon--round .van-icon {
  border-radius: 0px
}

.checkbox>>>.van-checkbox__icon--checked .van-icon {
  border: 1px solid #1ea77e;
  background-color: #1ea77e !important;
  color: #fff;
}
</style>
