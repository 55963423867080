<template>
  <div class="head-fall-back">
    <div class="handle-back" @click="$router.go(-1)">
      <img class="icon" :src="require('./left.png')" alt="back" />
    </div>
    <div class="title-text">
      <slot name="title"></slot>
    </div>
    <div class="other">
      <slot name="other"></slot>
    </div>
  </div>
</template>
<script>
export default {
  name: 'FallBack',
  props: {
    backPath: String
  }
};
</script>

<style lang="scss" scoped>
.head-fall-back {
  position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 90;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 50px;
    box-sizing: border-box;
    padding: 0 12px;
  .handle-back {
    width: 35px;
    height: 35px;
    background: rgb(255, 255, 255);
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    .icon {
      width: 80%;
      height: 80%;
    }
  }
  .title-text {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #222;
    max-width: 60%;
    font-size: 19px;
    font-weight: 700;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
</style>
